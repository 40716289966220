<template lang="html">
  <div class="" id="cotizacion">
    <div class="row w-full" v-if="email == ''">
      <div class="vx-col w-full md:w-3/3 sm:w-2/2 ">
        <vx-card class="text-center cursor-pointer">
          <img src="@/assets/images/pages/graphic-2.png" alt="graphic" width="180" class="mx-auto mb-4">
          <h4 class="mb-2">Añade un coacreditado</h4>
          <small>Regresa al apartado solicitante para agregar un coacreditado</small>
        </vx-card>
      </div>
    </div>
    <div class="row w-full" v-else>

      <vs-row vs-type="flex" vs-justify="flex-end">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12">
          <vs-button color="danger"  @click="eliminarCoacreditado()">Eliminar Coacreditado</vs-button>
        </vs-col>
      </vs-row>

      <div class="vx-col w-full">

        <div class="row w-full -mb-4">
          <div class="col-md-12">
            <h5 class="font-bold">Datos personales</h5> 
          </div>
			  </div>

        <div class="row w-full">
          <div class="vx-col lg:w-1/3 sm:w-full">
            <span class="span-placeholder">Correo cliente * </span>
            <input class="vs-input" label="Nombre(s)" v-model="email" name="username" disabled/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">CURP *</span>
            <input class="vs-input" label="Curp" v-model="curp" name="username" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">RFC *</span>
            <input class="vs-input" label="RFC" v-model="rfc" name="username" />
          </div>
        </div>
        <div class="row w-full">
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Nombre(s)</span>
              <input class="vs-input" label="Nombre(s)" v-model="nombre" name="username" />
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Apellido paterno</span>
              <input class="vs-input" label="Apellido Paterno" v-model="apellidoPaterno" name="name" />
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Apellido materno</span>
              <input class="vs-input" label="Apellido Materno" v-model="apellidoMaterno" name="name" />
              <span  v-if="error_apm" class="text-danger span-placeholder">Campo requerido</span>
            </div>
        </div>
        <div class="row w-full">
          <div class="col-md-3">
            <span for="sexo">Genero</span>
              <div class="alinear">
                <div class="col-md-4">
                  <span class="span-placeholder">Hombre</span>
                  <vs-radio v-model="sexo" vs-name="sexo" vs-value="H"></vs-radio>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Mujer</span>
                  <vs-radio v-model="sexo" vs-name="sexo" vs-value="M"></vs-radio>
                </div>
              </div>
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Teléfono celular</span>
            <input class="vs-input" label="Teléfono celular (10 dígitos)" v-model="telefono" name="username" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Teléfono fijo</span>
            <input class="vs-input" label="Teléfono fijo (10 dígitos)" v-model="adicionales.telefonoFijo" name="name" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Teléfono oficina</span>
            <input class="vs-input" label="Teléfono oficina (10 dígitos)" v-model="adicionales.telefonoOficina" name="name" />
          </div>
        </div>  
        <div class="row w-full">
          <div class="col-md-3">
            <span class="span-placeholder">Fecha de nacimiento</span>
            <input class="vs-input" label="Fecha de nacimiento" type="date" v-model="fechaNacimiento" name="fechaNacimiento" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">País de nacimiento</span>
            <select 
              name="name" 
              v-model="adicionales.paisNacimiento" 
              class="vs-input"
            >
              <option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
            </select>              
          </div>            
          <div class="col-md-3">
            <span class="span-placeholder" for="estados">Estado de nacimiento</span>
            <select name="cars" id="estados" v-model="adicionales.estadoNacimiento" class="vs-input">
              <option :value="''">seleccione...</option>
              <option :value="tipo.Id" v-for="tipo in estados" :key="">{{ tipo.Nombre }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Municipio de nacimiento</span>
            <input class="vs-input" label="Municipio de nacimiento" v-model="adicionales.municipioNacimiento" name="name" />
          </div>
        </div>

        <div class="row w-full">
          <div class="col-md-3" v-if="parentesco==1">
            <span class="span-placeholder" for="formasEnganche">Estado Civil</span>
            <select name="cars" id="formasEnganche" v-model="estadoCivil" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2);" >
              <option :value='1' >CASADO(A)</option>
            </select>
          </div>
          <div class="col-md-3">
            <span class="span-placeholder" for="nacionalidad">Nacionalidad</span>
            <select name="cars" id="nacionalidad" v-model="nacionalidad" class="vs-input">
              <option :value='tipo' v-for="tipo in nacionalidades"  :key="">{{tipo.Nombre}}</option>
              </select>
          </div>
        </div>


        <!-- DOMICILIO -->

        <div class="row w-full -mb-4" style="margin-top: 3%;">
          <div class="col-md-12">
            <h5 class="font-bold">Domicilio</h5> 
          </div>
			  </div>

        <div class="row w-full">
          <div class="col-md-3">
            <span class="span-placeholder">Calle</span>
            <input class="vs-input" label="Calle" v-model="calle" name="name" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Número exterior</span>
            <input class="vs-input" label="Número exterior" v-model="numeroExterior" name="name" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Número interior</span>
            <input class="vs-input" label="Número interior" v-model="numeroInterior" name="username" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Código postal</span>
            <input class="vs-input" label="Código postal" v-model="codigoPostal" name="username" @blur="getColoniaMunicipio(1)"/>
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-3">
            <span class="span-placeholder" v-if="LabelColoniaObligado">Colonia *</span>
            <span class="span-placeholder" v-if="LabelColoniaNoObligado">Colonia </span>
            <select 
              v-model="colonia" 
              name="colonia" class="vs-input">
              <option value="" selected>Selecciona una colonia</option>
              <option :value='colonia' v-for="colonia in ColoniasCP" :key="">{{colonia}}</option>
            </select>
            <span class="text-danger span-placeholder" v-if="LabelColoniaObligadoText">Campo requerido</span>
          </div>
          <div class="col-md-3">
            <span class="span-placeholder" for="estado">Estado</span>
            <select name="cars" id="estado" v-model="estado" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2);">
              <option :value="''">seleccione...</option>
              <option :value='tipo.Id' v-for="tipo in estados"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Ciudad</span>
            <input class="vs-input" label="Ciudad" v-model="ciudad" name="name" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Municipio</span>
            <input class="vs-input" label="Municipio" v-model="municipio" name="name" disabled/>
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder" for="domicilioOcupaciones">Titularidad de domicilio actual</span>
            <select name="cars" id="domicilioOcupaciones" v-model="adicionales.titularidadDomicilio" class="vs-input">
              <option :value="''">seleccione...</option>
              <option :value="tipo.Id" v-for="tipo in domicilioOcupaciones">{{ tipo.Nombre }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Tiempo ocupando el domicilio actual</span>            
            <div class="flex">                
                <span class="col-4">
                    <input 
                        class="vs-input input-antiguedad-am"
                        label="Tiempo ocupando el domicilio actual"
                        v-model="adicionales.tiempoOcupandoDomicilioActual"
                        name="name"/>
                </span>
                <span 
                    class="col-2 input-antiguedad-fecha">
                    <span>A</span>
                </span>
                <span class="col-4">
                    <input 
                        class="vs-input input-antiguedad-am"
                        label="Tiempo ocupando el domicilio actual"
                        v-model="adicionales.tiempoOcupandoDomicilioActualMeses"
                        name="name"/>
                </span>
                <span 
                    class="col-2 input-antiguedad-fecha">
                    <span>M</span>
                </span>
            </div>
          </div>
              <!--<div class="col-md-4">
                <span class="span-placeholder">Antigüedad Domicilio Anterior en años</span>
                <input class="vs-input" label="Antigüedad Domicilio Anterior en años" v-model="adicionales.antiguedadDomicilioAnterior" name="name" v-on:keypress="isNumber($event)" />
              </div>
            -->
          <div class="col-md-1"></div>
          <div class="col-md-3">
            <span class="span-placeholder">Antigüedad en la ciudad</span>         
            <div class="flex">
                
                <span class="col-4">
                    <input                        
                        class=" vs-input  input-antiguedad-am"
                        label="Antigüedad en la ciudad"
                        v-model="adicionales.antiguedadCiudad"
                        v-on:keypress="isNumber($event)"
                        name="username"/>
                </span>
                <span 
                    class="col-2 input-antiguedad-fecha">
                    <span>A</span>
                </span>

                <span class="col-4">
                    <input                        
                        class="vs-input input-antiguedad-am"
                        label="Antigüedad en la ciudad"
                        v-model="adicionales.antiguedadCiudadMeses"
                        v-on:keypress="isNumber($event)"
                        name="username"/>
                </span>
                <span 
                    class="col-2 input-antiguedad-fecha">
                    <span>M</span>
                </span>

            </div>

          </div>
        </div>

        <!-- PERSONALES -->

        <div class="row w-full -mb-4" style="margin-top: 3%;">
          <div class="col-md-12">
            <h5 class="font-bold">Personales</h5> 
          </div>
        </div>

        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder" for="estados">Identificación que presenta</span>
            <select name="cars" id="estados" v-model="adicionales.identificacionPresenta" class="vs-input">
              <option :value="''">seleccione...</option>
              <option :value="tipo.Id" v-for="tipo in identificaciones" :key="">{{ tipo.Nombre }}</option>
            </select>
          </div>        
          <div class="col-md-4">
            <span class="span-placeholder">Número de identificación</span>
            <input class="vs-input" label="Número de identificación" v-model="adicionales.numeroIfe" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Vigencia de identificación</span>
            <input class="vs-input" label="Vigencia Identificación" type="date" v-model="adicionales.vigencia" name="name" />
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-3">
            <span class="span-placeholder">Número de seguro social</span>
            <input class="vs-input" label="Número de seguro social" v-model="adicionales.nss" name="name" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Número de ISSSTE</span>
            <input class="vs-input" label="Número de ISSSTE" v-model="adicionales.issste" name="name" />
          </div>
          <div class="col-md-3">
            <vx-tooltip text="Requerido por: HSBC, INBURSA">
              <span class="span-placeholder">FIEL</span>
              <input class="vs-input" label="FIEL" v-model="adicionales.fiel" name="name" />
            </vx-tooltip>
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Compañia celular</span>        
            <select name="cars" v-model="adicionales.companiaCelular" class="vs-input">
              <option :value="''">Seleccione...</option>
              <option :value="compania.name" v-for="compania in companiasCelular" :key="compania.name">{{ compania.name }}</option>
            </select>
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-3">
            <span class="span-placeholder" for="grados">Máximo grado de estudios</span>
            <select name="cars" id="grados" v-model="adicionales.gradoEstudios" class="vs-input">
              <option :value="''">seleccione...</option>
              <option :value="tipo.Id" v-for="tipo in gradosEstudio" :key="">{{ tipo.Nombre }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Tipo de institución de estudios</span>
            <input class="vs-input" label="Tipo de institución de estudios" 
              v-model="adicionales.institucionEstudios" name="name" placeholder="Pública o Privada" />
          </div>
          <div class="col-md-6">
            <span class="span-placeholder">En caso de haber tenido otro nombre favor de indicarlo</span>
            <input class="vs-input" label="En caso de haber tenido otro nombre favor de indicarlo" v-model="adicionales.otroNombre" name="name" />
          </div>
        </div>    

        <!-- DOMICILIO DE CIUDADANO EXTRANJERO -->

        <div class="row w-full -mb-4" style="margin-top: 3%;">
          <div class="col-md-12">
            <h5 class="font-bold">Domicilio de ciudadano extranjero</h5> 
          </div>
        </div>

        <div class="row w-full">
          <div class="col-md-3">
            <span class="span-placeholder">Calle</span>
            <input class="vs-input" label="Calle" v-model="adicionales.calleExtranjero" name="name" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Número exterior</span>
            <input class="vs-input" label="Número exterior" v-model="adicionales.numeroExteriorExtranjero" name="name" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Número interior</span>
            <input class="vs-input" label="Número interior" v-model="adicionales.numeroInteriorExtranjero" name="name" />
          </div>
          <div class="col-md-3">
            <span class="span-placeholder">Colonia</span>
            <input class="vs-input" label="Colonia" v-model="adicionales.coloniaExtranjero" name="name" />
          </div>       
        </div>
        <div class="row w-full">							
          <div class="col-md-4">
            <span class="span-placeholder">Código postal</span>
            <input class="vs-input" label="Código postal" v-model="adicionales.codigoPostalExtranjero" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Estado</span>
            <input class="vs-input" label="Estado" v-model="adicionales.estadoExtranjero" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">País</span>
            <select 
              name="name" 
              v-model="adicionales.paisExtranjero" 
              class="vs-input"
            >
              <option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
            </select>								
          </div>							
        </div>

        <!-- VEHICULOS Y EXTRAS -->

        <div class="row w-full">
          <div class="col-md-12">
            <ul class="con-s" style="margin-top: 3%">
              <li>
                <span for="">¿Participa en crédito Infonavit o Fovissste (Banamex)?</span>
                <vs-switch v-model="adicionales.participaCreditoInfonavit" />
              </li>
            </ul>
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-12">
            <ul class="con-s" style="margin-top: 1%">
              <li>
                <span for="">¿Tiene Autos propios?</span>
                <vs-switch v-model="adicionales.autosPropios" />
              </li>
            </ul>
          </div>
        </div>

        <div v-if="adicionales.autosPropios">
          <div class="row w-full -mb-4" style="margin-top: 3%;">
            <div class="col-md-12">
              <h5 class="font-bold">Vehículos</h5> 
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Número de autos</span>
              <input class="vs-input" label="Número de autos" v-model="adicionales.vehiculos.numero" name="name" v-on:keypress="isNumber($event)" />
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Valor aproximado vehículo 1</span>
              <input class="vs-input" label="Valor aproximado vehículo 1" v-model="adicionales.vehiculos.valor" name="name" v-on:keypress="isNumber($event)" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Marca vehículo 1</span>
              <input class="vs-input" label="Marca vehículo 1" v-model="adicionales.vehiculos.marca" name="name" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Modelo vehículo 1</span>
              <input class="vs-input" label="Modelo vehículo 1" v-model="adicionales.vehiculos.modelo" name="name" />
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder" for="grados">Año vehículo 1</span>
              <select name="cars" id="grados" v-model="adicionales.vehiculos.anio" class="vs-input">
                <option :value="''">seleccione...</option>
                <option :value="tipo.Id" v-for="tipo in años" :key="">{{ tipo.Nombre }}</option>
              </select>
            </div>
            <div class="col-md-8 text-center">
              <span class="span-placeholder" for="pago">Pago vehículo 1</span>
              <div class="row">
                <div class="col-md-6 content-radio">
                  <vs-radio v-model="adicionales.vehiculos.pago" vs-name="pago" name="pago" vs-value="Completo"></vs-radio>
                  <span class="span-placeholder" for="Completo">Completo</span>
                </div>
                <div class="col-md-6 content-radio">
                  <vs-radio v-model="adicionales.vehiculos.pago" vs-name="pago" name="pago" vs-value="Parcial"></vs-radio>
                  <span class="span-placeholder" for="Parcial">Parcial</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Valor aproximado vehículo 2</span>
              <input class="vs-input" label="Valor aproximado vehículo 2" v-model="adicionales.vehiculos.valor2" name="name" v-on:keypress="isNumber($event)" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Marca vehículo 2</span>
              <input class="vs-input" label="Marca vehículo 2" v-model="adicionales.vehiculos.marca2" name="name" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Modelo vehículo 2</span>
              <input class="vs-input" label="Modelo vehículo 2" v-model="adicionales.vehiculos.modelo2" name="name" />
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder" for="grados">Año vehículo 2</span>
              <select name="cars" id="grados" v-model="adicionales.vehiculos.anio2" class="vs-input">
                <option :value="''">seleccione...</option>
                <option :value="tipo.Id" v-for="tipo in años" :key="">{{ tipo.Nombre }}</option>
              </select>
            </div>
            <div class="col-md-8 text-center">
              <span class="span-placeholder" for="pago2">Pago vehículo 2</span>
              <div class="row">
                <div class="col-md-6 content-radio">
                  <vs-radio v-model="adicionales.vehiculos.pago2" vs-name="pago2" name="pago2" vs-value="Completo"></vs-radio>
                  <span class="span-placeholder" for="Completo">Completo</span>
                </div>
                <div class="col-md-6 content-radio">
                  <vs-radio v-model="adicionales.vehiculos.pago2" vs-name="pago2" name="pago2" vs-value="Parcial"></vs-radio>
                  <span class="span-placeholder" for="Parcial">Parcial</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      
       
        
          
         
         
        
      


            

          
          

<!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->

      <!--
        <vx-card :title="'Coacreditado: '+email" class="">          
          <vs-row vs-type="flex" vs-justify="flex-end">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12">
              <vs-button color="danger"  @click="eliminarCoacreditado()">Eliminar Coacreditado</vs-button>
            </vs-col>
          </vs-row>
          <div class="row w-full" style="margin-top:2%">
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Nombre(s)</span>
              <input class="vs-input" label="Nombre(s)" v-model="nombre" name="username" />
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Apellido Paterno</span>
              <input class="vs-input" label="Apellido Paterno" v-model="apellidoPaterno" name="name" />
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Apellido Materno</span>
              <input class="vs-input" label="Apellido Materno" v-model="apellidoMaterno" name="name" />
            </div>
          </div>
          <div class="row w-full">
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Teléfono celular (10 dígitos)</span>
              <input class="vs-input" label="Teléfono celular (10 dígitos)" v-model="telefono" name="username" />
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Fecha de nacimiento</span>
              <input class="vs-input" label="Fecha de nacimiento" type="date" v-model="fechaNacimiento" name="fechaNacimiento" />
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full" v-if="parentesco!=1">
              <span class="span-placeholder" for="formasEnganche">Estado Civil</span>
              <select name="cars" id="formasEnganche" v-model="estadoCivil" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2);" @change="dameConyuge()">
                <option :value="''">seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in estadosCiviles"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full" v-if="parentesco==1">
              <span class="span-placeholder" for="formasEnganche">Estado Civil</span>
              <select name="cars" id="formasEnganche" v-model="estadoCivil" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2);" >
                <option :value='1' >CASADO(A)</option>
              </select>
            </div>
          </div>
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">RFC</span>
              <input class="vs-input" label="RFC" v-model="rfc" name="username" />
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">CURP</span>
              <input class="vs-input" label="Curp" v-model="curp" name="username" />
            </div>
            
              <div class="col-md-4">

                <span for="sexo">Sexo</span>
                <div class="alinear">
                <div class="col-md-4">
                  <span class="span-placeholder">Hombre</span>
                  <vs-radio v-model="sexo" vs-name="sexo" vs-value="H"></vs-radio>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Mujer</span>
                  <vs-radio v-model="sexo" vs-name="sexo" vs-value="M"></vs-radio>
                </div>
                </div>
              </div>
            
          </div>
          <div class="row w-full">
            <div class="col-md-3">
              <span class="span-placeholder">Calle</span>
              <input class="vs-input" label="Calle" v-model="calle" name="name" />
            </div>
            <div class="col-md-3">
              <span class="span-placeholder">Número exterior</span>
              <input class="vs-input" label="Número exterior" v-model="numeroExterior" name="name" />
            </div>
            <div class="col-md-3">
              <span class="span-placeholder">Número interior</span>
              <input class="vs-input" label="Número interior" v-model="numeroInterior" name="username" />
            </div>
            <div class="col-md-3">
              <span class="span-placeholder">Código postal</span>
              <input class="vs-input" label="Código postal" v-model="codigoPostal" name="username" @blur="getColoniaMunicipio(1)"/>
            </div>
          </div>
          <div class="row w-full">
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Municipio</span>
              <input class="vs-input" label="Municipio" v-model="municipio" name="name" disabled/>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder" v-if="LabelColoniaObligado">Colonia *</span>
              <span class="span-placeholder" v-if="LabelColoniaNoObligado">Colonia </span>
              <select 
                v-model="colonia" 
                name="colonia" class="vs-input">
                <option value="" selected>Selecciona una colonia</option>
                <option :value='colonia' v-for="colonia in ColoniasCP" :key="">{{colonia}}</option>
              </select>
              <span class="text-danger span-placeholder" v-if="LabelColoniaObligadoText">Campo requerido</span>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Ciudad</span>
              <input class="vs-input" label="Ciudad" v-model="ciudad" name="name" />
            </div>

          </div>
          <div class="row w-full">
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder" for="estado">Estado</span>
              <select name="cars" id="estado" v-model="estado" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2);">
                <option :value="''">seleccione...</option>
                <option :value='tipo.Id' v-for="tipo in estados"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
             <span class="span-placeholder" for="nacionalidad">Nacionalidad</span>
              <select name="cars" id="nacionalidad" v-model="nacionalidad" class="vs-input">
                <option :value='tipo' v-for="tipo in nacionalidades"  :key="">{{tipo.Nombre}}</option>
               </select>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <div class="row" style="padding-top: 14%;">
                <div class="col-8">
                  <span class="span-placeholder">Domicilio de solicitante</span>
                </div>
                <div class="col-2">
                  <vs-switch  v-model="DomicilioIgual" @click="asgnaDomicilio()"/>  
                </div>
              </div>
              
                        
             </div>
          </div>
          <br>
          <h4 class="mb-2" v-if="estadoCivil == 1 && conyugeIsCoacred=='FALSE'">Conyuge coacreditado</h4>
          <div class="row w-full" v-if="estadoCivil == 1 && conyugeIsCoacred=='FALSE'">
            
             <div class="vx-col lg:w-1/3 sm:w-full">
             <span class="span-placeholder">Nombre(s) cónyuge*</span>
              <input class="vs-input" label="Nombre(s) cónyuge*" v-model="nombreConyuge" name="name"/>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Apellido Paterno cónyuge*</span>
              <input class="vs-input" label="Apellido Paterno cónyuge*" v-model="apellidoPaternoConyuge" name="name"/>
            </div>
             <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Apellido Materno cónyuge*</span>
              <input class="vs-input" label="Apellido Materno cónyuge*" v-model="apellidoMaternoConyuge" name="name"/>
            </div>
          </div>
          <div class="row w-full" v-if="estadoCivil == 1 && conyugeIsCoacred=='FALSE'">
            <div class="vx-col lg:w-1/3 sm:w-full" >
              <span class="span-placeholder">CURP cónyuge</span>
              <input class="vs-input" label="CURP cónyuge" v-model="curpConyuge" name="name"/>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full" >
              <span class="span-placeholder">RFC cónyuge</span>
              <input class="vs-input" label="RFC cónyuge" v-model="rfcConyuge" name="name"/>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Fecha de nacimiento*</span>
              <input class="vs-input" label="Fecha de nacimiento*" type="date" v-model="fechaNacimientoConyuge" name="fechaNacimiento" />
            </div>
          </div>
           <div class="row w-full" v-if="estadoCivil == 1 && conyugeIsCoacred=='FALSE'">
            <div class="vx-col lg:w-1/6 sm:w-full">
              <span class="span-placeholder">Edad cónyuge</span>
              <input class="vs-input" label="Edad cónyuge" v-model="edadConyuge" name="name"/>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
               <span class="span-placeholder">Nacionalidad</span>
              <input class="vs-input" label="Nacionalidad" v-model="lugarNacimientoConyuge" name="name"/> 

              <span class="span-placeholder" for="lugarNacimientoConyuge">Nacionalidad</span>
              <select name="cars" id="lugarNacimientoConyuge" v-model="lugarNacimientoConyuge" class="vs-input">
                <option :value='tipo.Nombre' v-for="tipo in nacionalidades"  :key="">{{tipo.Nombre}}</option>
              </select>

            </div>
            <div class="vx-col lg:w-1/6 sm:w-full">
              <ul class="leftx">
                <span for="sexo">Sexo</span>
                <li>
                  <vs-radio v-model="sexoConyuge" vs-name="sexo" vs-value="Hombre">Hombre</vs-radio>
                </li>
                <li>
                  <vs-radio v-model="sexoConyuge" vs-name="sexo" vs-value="Mujer">Mujer</vs-radio>
                </li>
              </ul>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <input class="vs-input" label="Domicilio (Calle y número) cónyuge" v-model="domicilioConyuge" name="name"/>
            </div>
          </div>
          <div class="row w-full" v-if="estadoCivil == 1 && conyugeIsCoacred=='FALSE'">
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Colonia cónyuge</span>
              <input class="vs-input" label="Colonia cónyuge" v-model="coloniaConyuge" name="name"/>
            </div>
            <div class="vx-col lg:w-1/3 sm:w-full">
              <span class="span-placeholder">Municipio y entidad federativa cónyuge</span>
              <input class="vs-input" label="Municipio y entidad federativa cónyuge" v-model="municipioConyuge" name="name"/>
            </div>
            <div class="vx-col lg:w-1/6 sm:w-full">
                  <span class="span-placeholder">Código postal</span>
                  <input class="vs-input" label="Código postal" v-model="codigoPostalConyuge" name="name" />
            </div>
          </div>          
        </vx-card>

      -->
        <button class="btn btn-second" @click="actualizarInfo()" :disabled="!validateForm" style="margin-top: 5%;">Guardar información</button>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { nacionalidades } from '@/assets/jsonFiles/nacionalidades.js'
import { paisesNacimiento } from '@/assets/jsonFiles/paisesNacimiento.js'
export default {
  components:{
    'v-select': vSelect,
  },
  data(){
		return{
      id:'',
      key:'',
      email:'',
      nombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      telefono:'',
      fechaNacimiento:'',
      ingresosSinImpuestos:'',
      IngresosConImpuestos:'',
      estadoCivil:'',
      estadosCiviles:[],
      rfc:'',
      curp:'',
      sexo:'',
      calle:'',
      numeroInterior:'',
      numeroExterior:'',
      colonia:'',
      municipio:'',
      ColoniasCP: [],
			MunicipiosCP: [],
      ciudad:'',
      nacionalidad:'',
      estado:'',
      codigoPostal:'',
      estados:[],
      states: ['AGUASCALIENTES','BAJA CALIFORNIA NORTE','BAJA CALIFORNIA SUR','CAMPECHE',
      'CHIAPAS','CHIHUAHUA','COAHUILA','COLIMA','CIUDAD DE MEXICO','DURANGO','ESTADO DE MÉXICO',
      'GUANAJUATO','GUERRERO','HIDALGO','JALISCO','MICHOACAN','MORELOS','NAYARIT','NUEVO LEON',
      'OAXACA','PUEBLA','QUERETARO','QUINTANA ROO','SAN LUIS POTOSI','SINALOA','SONORA','TABASCO',
      'TAMAULIPAS','TLAXCALA','VERACRUZ','YUCATAN','ZACATECAS'],
      parentescos:[],
      paisesNac: [],
      solicitante:'',
      nombreConyuge:'',
      apellidoPaternoConyuge:'',
      apellidoMaternoConyuge:'',
      rfcConyuge:'',
      curpConyuge:'',
      fechaNacimientoConyuge:'',
      edadConyuge:'',
      nacionalidadConyuge:'',
      lugarNacimientoConyuge:'',
      sexoConyuge:'',
      domicilioConyuge:'',
      coloniaConyuge:'',
      municipioConyuge:'',
      codigoPostalConyuge:'',
      solicitante:'',
      conyugeIsCoacred:'TRUE',
      // nacionalidades:[
      //   { label: 'MEXICANA',  value: 'MEXICANO' },
      //   { label: 'EXTRANJERA',  value: 'EXTRANJERO' }
      // ],
      nacionalidades: [],

      AccreditedidV3 : false,
			Coacreditado : false,
			// header: {
			// 		"authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
			// 		},
      header: {},
      tokenSisec: '',
      solicitanteV3 : '',
      SocGuid:'',
      BrokerId: '',
      Id:'',
      LabelColoniaObligado: false,
      LabelColoniaNoObligado: true,
      LabelColoniaObligadoText: true,
      DomicilioIgual : '',     
      // DATOS ADICIONALES
      adicionales :{
        telefonoFijo:'',
        telefonoOficina:'',
        paisNacimiento: '',
        estadoNacimiento: '',
        municipioNacimiento:'',
        antiguedadDomicilioAnterior: '',
        tiempoOcupandoDomicilioActual: '',
        tiempoOcupandoDomicilioActualMeses: '',
        antiguedadCiudad: '',
        antiguedadCiudadMeses: '',
        titularidadDomicilio: '',
        identificacionPresenta: '',
        numeroIfe: '',
        vigencia: '',
        nss : '',
        issste: '',
        fiel: '',
        companiaCelular: '',
        gradoEstudios: '',
        institucionEstudios: '',
        otroNombre: '',
        calleExtranjero:'',
        numeroExteriorExtranjero:'',
        numeroInteriorExtranjero:'',
        coloniaExtranjero:'',
        estadoExtranjero:'',
        paisExtranjero:'',
        codigoPostalExtranjero:'',
        participaCreditoInfonavit:false,
        autosPropios:false,
        vehiculos:{
          numero:'',
          valor:'',
          marca:'',
          modelo:'',
          anio:'',
          pago: '',
          valor2:'',
          marca2:'',
          modelo2:'',
          anio2:'',
          pago2:''
        },





      }, 
      identificaciones:[],
      gradosEstudio:[],
      años:[],
      companiasCelular: [
        {name: 'Telcel'},
        {name: 'AT&T'},
        {name: 'Movistar'},
        {name: 'Unefon'},
        {name: 'Virgin Mobile'},
        {name: 'Freedompop'},
        {name: 'Flash Mobile'},
        {name: 'Weex'},
        {name: 'Cierto'},
        {name: 'Maz Tiempo'},
        {name: 'Oui móvil'},
        {name: 'Oxxo'},
        {name: 'Izzi móvil'},
        {name: 'Pillofon'},
        {name: 'BlueTelecomm'},
        {name: 'YO Mobile'},
        {name: 'Bait'},
        {name: 'Sky Celular'}
      ],
      error_apm: false
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getPaisesNacimiento()    
    this.getNacionalidades()
    this.getApikey()
    this.dameCaso()
    this.getEstados()
    this.getEstadosCiviles()
    this.getListaParentescos()
    this.DameCredenciales()
    this.getListaDomicilioOcupaciones()
    this.getIdentificaciones()
    this.getGradosEstudio()
    this.getAños()
  },
  watch: {
		// Formato mayusculas sin acento
		nombre: function(newValue, oldValue) {
			this.nombre = this.textFormat(newValue);
		},
		apellidoPaterno: function(newValue, oldValue) {
			this.apellidoPaterno = this.textFormat(newValue);
		},
		apellidoMaterno: function(newValue, oldValue) {
			this.apellidoMaterno = this.textFormat(newValue);
		},
    vehiculosValor:function(newValue){
      if (this.adicionales.vehiculos.valor != null ) {
        this.adicionales.vehiculos.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    vehiculosValor2:function(newValue){
      if (this.adicionales.vehiculos.valor2 != null ) {
        this.adicionales.vehiculos.valor2= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    }
  },
  computed:{
    validateForm(){     
      
      if ( (this.adicionales.paisNacimiento == "MÉXICO" || this.nacionalidad.Nombre == "MEXICANO (A)") && !this.apellidoMaterno) {
        this.error_apm = true
        return false
      }else{
        this.error_apm = false
      }

      if (this.codigoPostal == '' || this.codigoPostal == null) {
        this.LabelColoniaObligado = false
        this.LabelColoniaNoObligado = true
        this.LabelColoniaObligadoText = false
        return true
      }else{
        this.LabelColoniaObligado = true
        this.LabelColoniaNoObligado = false
        if (this.colonia) {
          this.LabelColoniaObligadoText = false
        }
        else
          this.LabelColoniaObligadoText = true

        return this.colonia != ''
      }
        
    },
    vehiculosValor(){
      return this.adicionales.vehiculos.valor
    },
    vehiculosValor2(){
      return this.adicionales.vehiculos.valor2
    },
  },
  methods:{
		// Conversion
		textFormat(strValue) {
			return strValue.normalize('NFD')
							.replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
							.normalize()
							.toUpperCase(); // Eliminar acentos menos Ññ
		},
    getAños(){
      let current=new Date().getFullYear()
      for (var i = 0; i < 31; i++) {
        this.años.push({ Nombre: current-i,  Id: current-i })
      }
    },
    getGradosEstudio(){
      let self=this
      var objRequestListaEscolaridades = {
        strApiKey: this.key,
        strMetodo: 'ListaEscolaridades',
      }
      this.$axios.post('/',objRequestListaEscolaridades,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.gradosEstudio=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEscolaridades',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getIdentificaciones(){
      let self=this
      var objRequestListaTipoIdentificaciones = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoIdentificaciones',
      }
      this.$axios.post('/',objRequestListaTipoIdentificaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let identificacionList = response.data.objContenido;
            identificacionList = identificacionList.filter(identi => identi.Id != 7);
            this.identificaciones = identificacionList;

            // 2023-05-04 Mejora en identificacion
            // this.identificaciones=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoIdentificaciones',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaDomicilioOcupaciones(){
      let self=this
      var objRequestListaDomicilioOcupaciones = {
        strApiKey: this.key,
        strMetodo: 'ListaDomicilioOcupaciones',
      }
      this.$axios.post('/',objRequestListaDomicilioOcupaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.domicilioOcupaciones=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaDomicilioOcupaciones',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getPaisesNacimiento() {
			this.paisesNac = paisesNacimiento;
		},
    async getAuthToken() {
      let objRequestToken = {
        strApiKey: this.key,
        strMetodo: 'DameAuthToken',
      }
      await this.$axios.post('/', objRequestToken,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then( response => {
          if (!response.data.error) {
            this.tokenSisec = "Bearer "+ response.data.access_token;
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error al obtener token',
              text: response.data.error_description,
              color: 'danger',
              position: 'top-right',
            })
          }
        }).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        });
    },
		getNacionalidades() {
			let nacionalidadesList = [];
			let index = 3;
			for (const nacionalidad of nacionalidades) {
				if (nacionalidad.Nombre === "MEXICANO (A)") {
					nacionalidadesList[0] = nacionalidad;
					continue;
				}
				if (nacionalidad.Nombre === "NORTEAMERICANO (A)") {
					nacionalidadesList[1] = nacionalidad;
					continue;
				}
				if (nacionalidad.Nombre === "CANADIENSE") {
					nacionalidadesList[2] = nacionalidad;
					continue;
				}
				nacionalidadesList[index] = nacionalidad;
				index ++;
			}
			this.nacionalidades = nacionalidadesList;
		},
    eliminarCoacreditado(){
      var eliminarCoacreditado = {
        strApiKey:this.key,
        strMetodo:'BorrarCoacreditado',
        objCaso:{
            Id:this.id
          }
      }
      this.$axios.post('/',eliminarCoacreditado,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Se eliminó exitosamente`,
              position:'top-right'
            })
            this.email=''
            if (this.AccreditedidV3) { //si el solicitante cuenta con pedidos en v3 entonces actualiza inf en liga cliente.
              this.generaInformacionParaV3(); 
            }

						this.$emit('changeCoacreditado', false)
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarCoacreditado',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    generaInformacionParaV3(){
			let self=this
        var objRequestDameInfo = {
          strFolioCaso: this.id,
          strApiKey: this.key,
          strMetodo: 'generaInformacionParaV3',
          objSolicitante:
          {
              EMail: this.solicitante
          },
          objCaso: {
          Id: this.Id,
          }
        }
        this.$axios.post('/',objRequestDameInfo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.solicitanteV3 = response.data.objContenido
              this.updateInfoEnV3()
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en generaInformacionParaV3',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
		},
    async updateInfoEnV3(){
      await this.getAuthToken();

			let self=this
				
			var mostrar = ""
			let objRequestUpdateInfo =
			{
			IsQuickQuote:false,
			AccreditedModel: {
				Name: this.solicitanteV3.Nombres,
				SName: this.solicitanteV3.SegundoNombre,
				LastName: this.solicitanteV3.ApellidoPaterno,
				SecondLastName: this.solicitanteV3.ApellidoMaterno,
				PersonalMail: this.solicitante,
				PhoneHome: this.solicitanteV3.TelefonoCasa,
				CellPhone: this.solicitanteV3.TelefonoCelular,
				DateBirtday: this.solicitanteV3.FechaNacimiento,
				Gender: this.solicitanteV3.Genero,
				Nationality: this.solicitanteV3.Nacionalidad,
				CURP: this.solicitanteV3.CURP,
				RFC: this.solicitanteV3.RFC,
				Weight: this.solicitanteV3.Peso,
				Height: this.solicitanteV3.Estatura,
				NSS: this.solicitanteV3.Nss,
				StudyGrade: this.solicitanteV3.GradoEstudios,
				CivilStatus: this.solicitanteV3.EstadoCivil,
				EconomicDependents: this.solicitanteV3.DependientesEconomicos,
				NumberOfChildren: this.solicitanteV3.NumeroHijos,
				ChildrensAge: this.solicitanteV3.EdadHijos,
				Cocredited: this.solicitanteV3.Coacreditado,
				//domicilio
				TypeHousing: this.solicitanteV3.TipoInmueble,
				ResidenceTimeHome: this.solicitanteV3.TiempoResidenciaActual,
				ResidenceTimeTown: this.solicitanteV3.TiempoResidenciaPoblacion,
				CP: this.solicitanteV3.CodigoPostal,
				//Bienes inmuebles
				NumberOfCars: this.solicitanteV3.NumeroAutos,				
				//Ingresos
				Activity: this.solicitanteV3.Sector,
				TypeCompany: this.solicitanteV3.TipoEmpleo,
				CompanyName: this.solicitanteV3.Empresa,
				CompanyAddress: this.solicitanteV3.DomicilioEmpresa,
				MonetaryIncome: this.solicitanteV3.Ingresos,
				Position: this.solicitanteV3.Puesto,
				DateBeginWork: this.solicitanteV3.FechaInicio,

				Age: this.solicitanteV3.Edad,		
			},
			ReferencesModel: {
				Name: this.solicitanteV3.NombreReferencia,
				SName: null,
				LastName: this.solicitanteV3.ApellidoPaternoRef,
				SecondLastName: this.solicitanteV3.ApellidoMaternoRef,
				Phone: this.solicitanteV3.TelCelularRef,
				Relationship: this.solicitanteV3.ParentescoRef,
				TimeRelationship: this.solicitanteV3.AnosConocerloRef
			},
			CreditRequestModel: {
				TypeCredit: null,
				HomeValue: null,
				CreditAmount: null,
				Plazo: null
			},
			BrokerEmployeeModel: {
        Id: this.SocGuid,
        BrokerId: this.BrokerId,
          //para pruebas comentar las dos de arriba, terminando descomentar arriba y commentar abajo
          //Id: "61711b5c-948c-4928-94f0-158b96b58da0",
          //BrokerId:"ca1b7cbd-f2a5-4507-9f03-4c167dcb2663",
				EmployeeName: null,
				EmployeeEmail: null,
				Origin: "Cotizador soc"
				
			},
			MaterialGoodsModel:{
				Properties:{
					NumberProperties: this.solicitanteV3.NumeroInmuebles,
					ApproximateValue: this.solicitanteV3.ValorAproximado,
					EncumberedValue: this.solicitanteV3.ValorGrabado,
				},
				CarOne:{
					ApproximateValueCar: this.solicitanteV3.ValorAuto1,
					MarkCar: this.solicitanteV3.MarcaAuto1,
					ModelCar: this.solicitanteV3.ModeloAuto1,
					YearCar: this.solicitanteV3.AnioAuto1,
				},
				CarTwo:{
					ApproximateValueCar: this.solicitanteV3.ValorAuto2,
					MarkCar: this.solicitanteV3.MarcaAuto2,
					ModelCar: this.solicitanteV3.ModeloAuto2,
					YearCar: this.solicitanteV3.AnioAuto2
				}
			}

			
			}   
			this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestUpdateInfo,{headers : {"Authorization": this.tokenSisec}})
				.then(
				response => {

				}
				).catch(function (error) {
					if(error.response.data.Message == "The CellPhone already exists"){
					mostrar = "El telefono ya se encuentra registrado intente con otro"
					}else if(error.response.data.Message == "The email already exists"){
					mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
					}else{
					mostrar = error
					}
					
					self.$vs.notify({
						title:'Ocurrio un error en SISEC',
						text:mostrar,
						color:'danger',
						position:'top-right'
					})
				})

		},
    DameCredenciales(){
        let self=this
        let objRequestDameCredenciales = {
          strApiKey: this.key,
          strMetodo: 'DameCredenciales',
          objBroker: {
          ApiKey: this.key
          }
        }
        this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
          this.SocGuid = response.data.objContenido.SocGuid
          this.BrokerId = response.data.objContenido.BrokerId

          //this.SocGuid = '61711b5c-948c-4928-94f0-158b96b58da0',
          //this.BrokerId = 'ca1b7cbd-f2a5-4507-9f03-4c167dcb2663';

          }
        ).catch(function (error) {
          self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
          })
        })
    },
    actualizarInfo(){
       this.$vs.loading({
				container: '#cotizacion',
				scale: 0.6,
	     })
      let self= this
      let sexo
      if(this.sexo== 'H'){
        sexo=1
      }else{
        sexo=2
      }
      let objRequestRegistraSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          EMail: this.email,
          Nombres: this.nombre,
          ApellidoPaterno: this.apellidoPaterno,
          ApellidoMaterno: this.apellidoMaterno,
          TelefonoCelular: this.telefono,
          FechaNacimiento: this.fechaNacimiento,
          Rfc: this.rfc,
          Curp: this.curp,
      		Sexo:sexo,
      		Calle:this.calle,
      		NumeroExterior:this.numeroExterior,
          NumeroInterior:this.numeroInterior,
      		Colonia:this.colonia,
      		Municipio:this.municipio,
      		Ciudad:this.ciudad,
      		Estado:this.estado,
      		CodigoPostal:this.codigoPostal,
          // Nacionalidad:this.nacionalidad,
					Nacionalidad: {
						id: this.nacionalidad.Id,
						name: this.nacionalidad.Nombre,
						updateScotia: true,
					},

          EstadoCivil:this.estadoCivil,
          //obligado conyuge
          NombreConyugeObligado:this.nombreConyuge,
          ApellidoPaternoConyugeObligado:this.apellidoPaternoConyuge,
          ApellidoMaternoConyugeObligado:this.apellidoMaternoConyuge,
          RFCConyugeObligado:this.rfcConyuge,
          CURPConyugeObligado:this.curpConyuge,
          FechaNacimientoConyugeObligado:this.fechaNacimientoConyuge,
          EdadConyugeObligado:this.edadConyuge,
          NacionalidadConyugeObligado:this.nacionalidadConyuge,
          // LugarNacimientoConyugeObligado:this.lugarNacimientoConyuge,
          LugarNacimientoConyugeObligado: {
            name: this.lugarNacimientoConyuge,
            noSanitiza: true,
					},

          SexoConyugeObligado:this.sexoConyuge,
          DomicilioConyugeObligado:this.domicilioConyuge,
          ColoniaConyugeObligado:this.coloniaConyuge,
          MunicipioConyugeObligado:this.municipioConyuge,
          CodigoPostalConyugeObligado:this.codigoPostalConyuge,

          ViveMismoDomicilioTitular : this.DomicilioIgual
        }
      }
      this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.$vs.loading.close('#cotizacion > .con-vs-loading')  
            this.registraAdicionales()             
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraAdicionales(){
      var auto1Valor = '';
      var auto2Valor = '';
      
      if (!this.adicionales.autosPropios) {
          this.adicionales.autosPropios = null;
          this.adicionales.vehiculos.numero = null;
          this.adicionales.vehiculos.valor = '';
          this.adicionales.vehiculos.marca = null;
          this.adicionales.vehiculos.modelo = null;
          this.adicionales.vehiculos.anio = null;

          this.adicionales.vehiculos.pago = null;
          this.adicionales.vehiculos.valor2 = '';
          this.adicionales.vehiculos.marca2 = null;
          this.adicionales.vehiculos.modelo2 = null;
          this.adicionales.vehiculos.anio2 = null;
          this.adicionales.vehiculos.pago2 = null;
      }
      else{
        auto1Valor = parseFloat(this.adicionales.vehiculos.valor.replace(/,/g,""));
        auto2Valor = parseFloat(this.adicionales.vehiculos.valor2.replace(/,/g,""));
      }

      let self=this
      var objRequestAdicionales = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'RegistraCoacreditadoAdicionales',
        objSolicitante: {
        EMail:this.email,
        Personales:{
          CompaniaCelular:this.adicionales.companiaCelular,
          IdentificacionPresenta:this.adicionales.identificacionPresenta,
          NumeroIdentificacion:this.adicionales.numeroIfe,
          Nss:this.adicionales.nss,
          Issste: this.adicionales.issste,
          Fiel:this.adicionales.fiel,
          TelefonoFijo:this.adicionales.telefonoFijo,
          TelefonoOficina:this.adicionales.telefonoOficina,
          VigenciaIdentificacion:this.adicionales.vigencia,
          TitularidadDomicilio:this.adicionales.titularidadDomicilio,
          ParticipaCredito:this.adicionales.participaCreditoInfonavit,
          CalleExtranjero:this.adicionales.calleExtranjero,
          NumeroExteriorExtranjero:this.adicionales.numeroExteriorExtranjero,
          NumeroInteriorExtranjero:this.adicionales.numeroInteriorExtranjero,
          ColoniaExtranjero:this.adicionales.coloniaExtranjero,
          EstadoExtranjero:this.adicionales.estadoExtranjero,
          // PaisExtranjero:this.adicionales.paisExtranjero,
          PaisExtranjero: {
            name: this.adicionales.paisExtranjero,
            noSanitiza: true,
          },
          CodigoPostalExtranjero:this.adicionales.codigoPostalExtranjero,
          PaisRecidencia:this.adicionales.PaisRecidencia
        },
        Estudios:{
          GradoEstudios:this.adicionales.gradoEstudios,
          InstitucionEstudios : this.adicionales.institucionEstudios,
        },        
        Nacionalidad:{
          OtroNombre:this.adicionales.otroNombre,
          EstadoNacimiento:this.adicionales.estadoNacimiento,
          MunicipioNacimiento:this.adicionales.municipioNacimiento,
          // PaisNacimiento:this.adicionales.paisNacimiento,
          PaisNacimiento: {
            name: this.adicionales.paisNacimiento,
            noSanitiza: true,
          },
        },
        DomicilioActual:{
          TiempoOcupandoDomicilioActual:this.adicionales.tiempoOcupandoDomicilioActual,
          //AntiguedadDomicilioAnterior:this.adicionales.antiguedadDomicilioAnterior,
          AntiguedadCiudad:this.adicionales.antiguedadCiudad,
          AntiguedadEnCiudadMeses: this.adicionales.antiguedadCiudadMeses,

          TiempoViviendoCiudadMeses: this.adicionales.tiempoOcupandoDomicilioActualMeses,
        },
        Propiedades:{
          VehiculosPropios:this.adicionales.autosPropios,
          NumeroVehiculos:this.adicionales.vehiculos.numero,
          Auto1Valor:auto1Valor,
          Auto1Marca:this.adicionales.vehiculos.marca,
          Auto1Modelo:this.adicionales.vehiculos.modelo,
          Auto1Anio:this.adicionales.vehiculos.anio,

          Auto1Pago:this.adicionales.vehiculos.pago,
          Auto2Valor:auto2Valor,
          Auto2Marca:this.adicionales.vehiculos.marca2,
          Auto2Modelo:this.adicionales.vehiculos.modelo2,
          Auto2Anio:this.adicionales.vehiculos.anio2,
          Auto2Pago:this.adicionales.vehiculos.pago2,
        },
      }
    }
      this.$axios.post('/',objRequestAdicionales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Guardado exitosamente`,
              position:'top-right'
            }) 
            this.dameSolicitante()                     
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAdicionalesSolicitud',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            //console.log(response.data.objContenido)
            if (response.data.objContenido.Coacreditado[0]) {
              this.email= response.data.objContenido.Coacreditado[0].EMail
              this.solicitante=response.data.objContenido.Solicitante
              this.parentesco=response.data.objContenido.Coacreditado[0].Parentesco
              this.Id = response.data.objContenido.Id
              this.dameSolicitante()
            }
           //  alert(this.parentesco)
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let solicitante =response.data.objContenido
            this.nombre= solicitante.Nombres
            this.apellidoPaterno= solicitante.ApellidoPaterno
            this.apellidoMaterno= solicitante.ApellidoMaterno
            this.telefono= solicitante.TelefonoCelular
            this.fechaNacimiento= solicitante.FechaNacimiento
            this.rfc=solicitante.Rfc
            if(solicitante.Sexo== 1){
              this.sexo='H'
            }else if (solicitante.Sexo== 2) {
              this.sexo='M'
            }
            this.calle = solicitante.Calle
            this.numeroExterior = solicitante.NumeroExterior
            this.numeroInterior = solicitante.NumeroInterior
            this.colonia = solicitante.Colonia
            this.ciudad = solicitante.Ciudad
            this.municipio = solicitante.Municipio
            this.curp = solicitante.Curp
            this.codigoPostal = solicitante.CodigoPostal
            this.DomicilioIgual = solicitante.ViveMismoDomicilioTitular  // ticket #85059     
            //ADICIONALES
            this.adicionales.telefonoOficina=solicitante.TelefonoOficina
            this.adicionales.telefonoFijo=solicitante.TelefonoCasa   
            this.adicionales.municipioNacimiento=solicitante.MunicipioNacimiento
            this.adicionales.paisNacimiento=solicitante.PaisNacimiento        
            if(solicitante.EstadoNacimiento==null)
              this.adicionales.estadoNacimiento= ''
            else
              this.adicionales.estadoNacimiento=solicitante.EstadoNacimiento 
            
            if(solicitante.TipoOcupacion==null)
              this.adicionales.titularidadDomicilio= ''
            else
              this.adicionales.titularidadDomicilio= solicitante.TipoOcupacion

            this.adicionales.tiempoOcupandoDomicilioActual= solicitante.TiempoViviendoCiudad
            this.adicionales.tiempoOcupandoDomicilioActualMeses = solicitante.TiempoViviendoCiudadMeses ? solicitante.TiempoViviendoCiudadMeses : 0;
            this.adicionales.antiguedadCiudad= solicitante.AntiguedadEnCiudad ?  solicitante.AntiguedadEnCiudad : 0;
            this.adicionales.antiguedadCiudadMeses= solicitante.AntiguedadEnCiudadMeses ?  solicitante.AntiguedadEnCiudadMeses : 0;
            this.adicionales.antiguedadDomicilioAnterior = solicitante.AntiguedadDomicilioAnterior

            if(solicitante.Identificacion==null)
              this.adicionales.identificacionPresenta= ''
            else  
              this.adicionales.identificacionPresenta= solicitante.Identificacion

            this.adicionales.numeroIfe = solicitante.NumeroIdentificacion
            this.adicionales.vigencia = solicitante.VigenciaIdentificacion
            this.adicionales.companiaCelular = solicitante.CompaniaCelular
            this.adicionales.nss = solicitante.Nss
            this.adicionales.fiel = solicitante.Fiel
            this.adicionales.issste= solicitante.Issste

            if(solicitante.Escolaridad == null)
              this.adicionales.gradoEstudios= ''
            else 
              this.adicionales.gradoEstudios = solicitante.Escolaridad

            this.adicionales.institucionEstudios = solicitante.EscolaridadInstitucion
            this.adicionales.otroNombre = solicitante.OtroNombreAnterior

            // DOMICILIO DE CIUDADANO EN EXTRAJERO
            this.adicionales.calleExtranjero = solicitante.CalleExtranjero
            this.adicionales.numeroExteriorExtranjero=   solicitante.NumeroExteriorExtranjero
            this.adicionales.numeroInteriorExtranjero=   solicitante.NumeroInteriorExtranjero
            this.adicionales.coloniaExtranjero=   solicitante.ColoniaExtranjero
            this.adicionales.estadoExtranjero = solicitante.EstadoExtranjero
            this.adicionales.paisExtranjero = solicitante.PaisExtranjero
            this.adicionales.codigoPostalExtranjero = solicitante.CodigoPostalExtranjero

            // AUTOS PROPIOS
            this.adicionales.participaCreditoInfonavit = solicitante.ParticipaInfovIssste
            this.adicionales.autosPropios = solicitante.AutosPropios
            this.adicionales.vehiculos.numero=solicitante.AutosPropiosNumero
            this.adicionales.vehiculos.marca=solicitante.AutosPropiosMarca
            this.adicionales.vehiculos.modelo=solicitante.AutosPropiosModelo
            if(solicitante.AutosPropiosAno==null)
              this.adicionales.vehiculos.anio= ''
            else
              this.adicionales.vehiculos.anio=solicitante.AutosPropiosAno

            this.adicionales.vehiculos.pago=solicitante.AutosPropiosPago

            this.adicionales.vehiculos.marca2=solicitante.AutosPropios2Marca
            this.adicionales.vehiculos.modelo2=solicitante.AutosPropios2Modelo
            if(solicitante.AutosPropios2Ano==null)
              this.adicionales.vehiculos.anio2= ''
            else
            this.adicionales.vehiculos.anio2=solicitante.AutosPropios2Ano

            this.adicionales.vehiculos.pago2=solicitante.AutosPropios2Pago
            if (solicitante.AutosPropiosValor) {
              this.adicionales.vehiculos.valor=(solicitante.AutosPropiosValor).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if (solicitante.AutosPropios2Valor) {
              this.adicionales.vehiculos.valor2=(solicitante.AutosPropios2Valor).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }

            if (solicitante.CodigoPostal != '' || solicitante.CodigoPostal != null) {
              this.getColoniaMunicipio(0)
            }

            if(solicitante.Estado==null)
              this.estado=''
            else
              this.estado=solicitante.Estado

            if(solicitante.EstadoCivil==null)
              this.estadoCivil=''
            else
              this.estadoCivil=solicitante.EstadoCivil   

            // this.nacionalidad=solicitante.Nacionalidad
            if (solicitante.ScotiaNacionalidad && solicitante.Nacionalidad) {
              this.nacionalidad = {
                Id: solicitante.ScotiaNacionalidad,
                Nombre: solicitante.Nacionalidad,
              };
            } else {
              this.nacionalidad = '';
            }

            this.dameConyuge()

            //obligado conyuge
            this.nombreConyuge = solicitante.NombreConyugeObligado
           this.apellidoPaternoConyuge = solicitante.ApellidoPaternoConyugeObligado
           this.apellidoMaternoConyuge = solicitante.ApellidoMaternoConyugeObligado
           this.rfcConyuge = solicitante.RFCConyugeObligado
           this.curpConyuge = solicitante.CURPConyugeObligado
           this.fechaNacimientoConyuge = solicitante.FechaNacimientoConyugeObligado
           this.edadConyuge = solicitante.EdadConyugeObligado
           this.nacionalidadConyuge = solicitante.NacionalidadConyugeObligado
           this.lugarNacimientoConyuge = solicitante.LugarNacimientoConyugeObligado
           this.sexoConyuge = solicitante.SexoConyugeObligado
           this.domicilioConyuge = solicitante.DomicilioConyugeObligado
           this.coloniaConyuge = solicitante.ColoniaConyugeObligado
           this.municipioConyuge = solicitante.MunicipioConyugeObligado
           this.codigoPostalConyuge = solicitante.CodigoPostalConyugeObligado

           this.dameSolicitantePrincipal();
           
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitantePrincipal(){
      
			let self = this
			var objRequestDameSolicitante = {
        strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.solicitante,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					const status = response.data.intEstatus
					const dataSolicitante = response.data.objContenido

          if (dataSolicitante.AccreditedIdV3 !=null && dataSolicitante.AccreditedIdV3 != '') {
            this.AccreditedidV3 = true;
          }

          if ( status ) {

						this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })

					}		  
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    getEstadosCiviles(){
      let self= this
      var objRequestListaEstadosCiviles = {
        strApiKey: this.key,
        strMetodo: 'ListaEstadosCiviles',
      }
      this.$axios.post('/',objRequestListaEstadosCiviles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estadosCiviles=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstadosCiviles',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getStateName(id){
      return this.states[id-1]
    },
    getListaParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.parentescos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameConyuge(){
       let self= this
      var objRequestDameConyuge = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameConyuge',
        objSolicitante:
        { EMailSolicitante:this.solicitante, 
          EMail:this.email
        }
      }
      this.$axios.post('/',objRequestDameConyuge,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.conyugeIsCoacred=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameConyuge',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameDireccion(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: this.solicitante
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let solicitante =response.data.objContenido
            this.calle = solicitante.Calle
            this.numeroExterior = solicitante.NumeroExterior
            this.numeroInterior = solicitante.NumeroInterior
            this.colonia = solicitante.Colonia
            this.ciudad = solicitante.Ciudad
            this.municipio = solicitante.Municipio
            this.codigoPostal = solicitante.CodigoPostal
            if (solicitante.CodigoPostal) {
              this.getColoniaMunicipio(0);
            }
            this.estado = solicitante.Estado
            //this.DomicilioIgual = true          

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },    
    asgnaDomicilio(){            
      //console.log(this.conyugeIsCoacred)
      if(!this.DomicilioIgual){
          this.dameDireccion()
        }else{                                  
            this.calle = ""
            this.numeroExterior = ""
            this.numeroInterior = ""
            this.colonia = ""
            this.ciudad = ""
            this.municipio = ""
            this.codigoPostal = ""
            this.estado = ""                   
        }
    },
    async getColoniaMunicipio(value){
      var origen = 0;

      if (value == 1) {
				this.colonia = '';	
        origen = 1;
			}

        const getToken = await this.getTokenSisec();
        var token = getToken.objRespuesta.token
        const dataInfoCP = await this.getInformationCP(token)

        if ( !dataInfoCP ) {
          this.colonia = ''
          this.municipio = ''
          return
        }
        
        const colonias = dataInfoCP.map(x => x.colonia)
        this.ColoniasCP = colonias
        this.municipio = dataInfoCP[0].municipio

        if (origen == 0) {
				if(colonias.indexOf(this.colonia) == -1){  // no se encontro
					this.colonia = '';
				}
			}
		
		},
		async getTokenSisec() {
			const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getTokenSisec.php'

			const { data, status } = await this.$axios
						.post(URL_Method,{headers : {'Content-Type':'application/json; charset=UTF-8'}})

			if ( status !== 200 ) {

				this.$vs.notify({
				title: 'Ocurrio un error en getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			if ( data.intRespuesta ) {

				this.$vs.notify({
				title: 'Ocurrio un error en Metodo getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			if ( !data.objRespuesta.status ) {

				this.$vs.notify({
				title: 'Ocurrio un error en API getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			//console.log(data)
			return data;

		},
		async getInformationCP(token) {

				const jsonCP = {
									token,
									CodigoPostal: this.codigoPostal
								}

				const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getInfoCP.php'

				const { status, data } = await this.$axios
						.post(URL_Method,jsonCP,{headers : {'Content-Type':'application/json; charset=UTF-8'}})

				if ( status !== 200 ) {

					this.$vs.notify({
						title: 'Ocurrio un error en getInformationCP',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})

					return false

				}

				if ( data.intRespuesta ) {

					this.$vs.notify({
						title: 'Ocurrio un error en Metodo getInformationCP',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})

					return false

				}

				if ( data.objRespuesta.message ) {

          this.$vs.notify({
          title: data.objRespuesta.message,
          text: data.strError,
          color: 'danger',
          position: 'top-right',
          })

          return false

        }

        if ( !data.objRespuesta.length ) {
          return false
        }

				return data.objRespuesta

		}
    
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}

.content-radio {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
