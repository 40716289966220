<template lang="html">
	<div class="accordion" id="accordionExample4">

    <!--  Se retira apartado  COACREDITADO > DATOS ADICIONALES > PERSONALES por requerimiento  Reacomodo de campos - Comparador  febrero / 2024 << se comenta apartado por si llegase a ocupar >>            -->
    <!--

      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne-dat">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne-dat" aria-expanded="true" aria-controls="collapseOne-dat">Personales</button>
        </h2>
        <div id="collapseOne-dat" class="accordion-collapse collapse" aria-labelledby="headingOne-dat" data-bs-parent="#accordionExample4">
          <div class="accordion-body accordion-scrolling mh-4">
            <div class="row w-full">
              <div class="row w-full">
                <div class="col-md-5">
                  <span class="span-placeholder" for="grados">Máximo grado de estudios</span>
                  <select name="cars" id="grados" v-model="adicionales.gradoEstudios" class="vs-input">
                    <option :value="''">seleccione...</option>
                    <option :value="tipo.Id" v-for="tipo in gradosEstudio" :key="">{{ tipo.Nombre }}</option>
                  </select>
                </div>
                <div class="col-md-7">
                  <span class="span-placeholder">En caso de haber tenido otro nombre favor de indicarlo</span>
                  <input class="vs-input" label="En caso de haber tenido otro nombre favor de indicarlo" v-model="adicionales.otroNombre" name="name" />
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder" for="estados">Estado de nacimiento</span>
                  <select name="cars" id="estados" v-model="adicionales.estadoNacimiento" class="vs-input">
                    <option :value="''">seleccione...</option>
                    <option :value="tipo.Id" v-for="tipo in estados" :key="">{{ tipo.Nombre }}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Municipio de nacimiento</span>
                  <input class="vs-input" label="Municipio de nacimiento" v-model="adicionales.municipioNacimiento" name="name" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">País de nacimiento</span>
                  <select 
                    name="name" 
                    v-model="adicionales.paisNacimiento" 
                    class="vs-input"
                  >
                    <option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
                  </select>

                  
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Teléfono fijo (10 dígitos)</span>
                  <input class="vs-input" label="Teléfono fijo (10 dígitos)" v-model="adicionales.telefonoFijo" name="name" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Teléfono oficina (10 dígitos)</span>
                  <input class="vs-input" label="Teléfono oficina (10 dígitos)" v-model="adicionales.telefonoOficina" name="name" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder" for="estados">Identificación que presenta</span>
                  <select name="cars" id="estados" v-model="adicionales.identificacionPresenta" class="vs-input">
                    <option :value="''">seleccione...</option>
                    <option :value="tipo.Id" v-for="tipo in identificaciones" :key="">{{ tipo.Nombre }}</option>
                  </select>
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Número de identificación</span>
                  <input class="vs-input" label="Número de identificación" v-model="adicionales.numeroIfe" name="name" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Vigencia de identificación</span>
                  <input class="vs-input" label="Vigencia Identificación" type="date" v-model="adicionales.vigencia" name="name" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Número de seguro social</span>
                  <input class="vs-input" label="Número de seguro social" v-model="adicionales.nss" name="name" />
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <vx-tooltip text="Requerido por: HSBC, INBURSA">
                    <span class="span-placeholder">FIEL</span>
                    <input class="vs-input" label="FIEL" v-model="adicionales.fiel" name="name" />
                  </vx-tooltip>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder" for="domicilioOcupaciones">Titularidad de domicilio actual</span>
                  <select name="cars" id="domicilioOcupaciones" v-model="adicionales.titularidadDomicilio" class="vs-input">
                    <option :value="''">seleccione...</option>
                    <option :value="tipo.Id" v-for="tipo in domicilioOcupaciones">{{ tipo.Nombre }}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Compañia celular</span>
                  
                  <select name="cars" v-model="adicionales.companiaCelular" class="vs-input">
                    <option :value="''">Seleccione...</option>
                    <option :value="compania.name" v-for="compania in companiasCelular" :key="compania.name">{{ compania.name }}</option>
                  </select>
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">País de residencia</span>
                  <input class="vs-input" label="País de residencia" v-model="adicionales.PaisRecidencia" name="name" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Tiempo ocupando el domicilio actual</span>
                  
                  <div class="flex">
                      
                      <span class="col-4">
                          <input 
                              class="vs-input input-antiguedad-am"
                              label="Tiempo ocupando el domicilio actual"
                              v-model="adicionales.tiempoOcupandoDomicilioActual"
                              name="name"/>
                      </span>
                      <span 
                          class="col-2 input-antiguedad-fecha">
                          <span>A</span>
                      </span>

                      <span class="col-4">
                          <input 
                              class="vs-input input-antiguedad-am"
                              label="Tiempo ocupando el domicilio actual"
                              v-model="adicionales.tiempoOcupandoDomicilioActualMeses"
                              name="name"/>
                      </span>
                      <span 
                          class="col-2 input-antiguedad-fecha">
                          <span>M</span>
                      </span>

                  </div>

                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Antigüedad Domicilio Anterior en años</span>
                  <input class="vs-input" label="Antigüedad Domicilio Anterior en años" v-model="adicionales.antiguedadDomicilioAnterior" name="name" v-on:keypress="isNumber($event)" />
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-6">
                  <ul class="con-s" style="margin-top: 5%">
                    <li>
                      <span for="">¿Participa en crédito Infonavit o Fovissste (Banamex)?</span>
                      <vs-switch v-model="adicionales.participaCreditoInfonavit" />
                    </li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul class="con-s" style="margin-top: 5%">
                    <li>
                      <span for="">¿Tiene Autos propios?</span>
                      <vs-switch v-model="adicionales.autosPropios" />
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-12">
                  <h4>Datos ciudadano extranjero</h4>
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Calle</span>
                  <input class="vs-input" label="Calle" v-model="adicionales.calleExtranjero" name="name" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Número exterior</span>
                  <input class="vs-input" label="Número exterior" v-model="adicionales.numeroExteriorExtranjero" name="name" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Número interior</span>
                  <input class="vs-input" label="Número interior" v-model="adicionales.numeroInteriorExtranjero" name="name" />
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Colonia</span>
                  <input class="vs-input" label="Colonia" v-model="adicionales.coloniaExtranjero" name="name" />
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Estado</span>
                  <input class="vs-input" label="Estado" v-model="adicionales.estadoExtranjero" name="name" />
                </div>
                <div class="col-md-2">
                  <span class="span-placeholder">País</span>
                  <select 
                    name="name" 
                    v-model="adicionales.paisExtranjero" 
                    class="vs-input"
                  >
                    <option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
                  </select>

                  
                </div>
                <div class="col-md-2">
                  <span class="span-placeholder">Código postal</span>
                  <input class="vs-input" label="Código postal" v-model="adicionales.codigoPostalExtranjero" name="name" />
                </div>
              </div>
              <div v-if="adicionales.autosPropios">
                <div class="row w-full">
                  <div class="col-md-12">
                    <h4>Vehículos</h4>
                  </div>
                  <div class="row w-full">
                    <div class="col-md-4">
                      <span class="span-placeholder">Número de autos</span>
                      <input class="vs-input" label="Número de autos" v-model="adicionales.vehiculos.numero" name="name" v-on:keypress="isNumber($event)" />
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                  <div class="row w-full">
                    <div class="col-md-4">
                      <span class="span-placeholder">Valor aproximado vehículo 1</span>
                      <input class="vs-input" label="Valor aproximado vehículo 1" v-model="adicionales.vehiculos.valor" name="name" v-on:keypress="isNumber($event)" />
                    </div>
                    <div class="col-md-4">
                      <span class="span-placeholder">Marca vehículo 1</span>
                      <input class="vs-input" label="Marca vehículo 1" v-model="adicionales.vehiculos.marca" name="name" />
                    </div>
                    <div class="col-md-4">
                      <span class="span-placeholder">Modelo vehículo 1</span>
                      <input class="vs-input" label="Modelo vehículo 1" v-model="adicionales.vehiculos.modelo" name="name" />
                    </div>
                  </div>
                  <div class="row w-full">
                    <div class="col-md-4">
                      <span class="span-placeholder" for="grados">Año vehículo 1</span>
                      <select name="cars" id="grados" v-model="adicionales.vehiculos.anio" class="vs-input">
                        <option :value="''">seleccione...</option>
                        <option :value="tipo.Id" v-for="tipo in años" :key="">{{ tipo.Nombre }}</option>
                      </select>
                    </div>
                    <div class="col-md-8 text-center">
                      <span class="span-placeholder" for="pago">Pago vehículo 1</span>
                      <div class="row">
                        <div class="col-md-6 content-radio">
                          <vs-radio v-model="adicionales.vehiculos.pago" vs-name="pago" name="pago" vs-value="Completo"></vs-radio>
                          <span class="span-placeholder" for="Completo">Completo</span>
                        </div>
                        <div class="col-md-6 content-radio">
                          <vs-radio v-model="adicionales.vehiculos.pago" vs-name="pago" name="pago" vs-value="Parcial"></vs-radio>
                          <span class="span-placeholder" for="Parcial">Parcial</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row w-full">
                    <div class="col-md-4">
                      <span class="span-placeholder">Valor aproximado vehículo 2</span>
                      <input class="vs-input" label="Valor aproximado vehículo 2" v-model="adicionales.vehiculos.valor2" name="name" v-on:keypress="isNumber($event)" />
                    </div>
                    <div class="col-md-4">
                      <span class="span-placeholder">Marca vehículo 2</span>
                      <input class="vs-input" label="Marca vehículo 2" v-model="adicionales.vehiculos.marca2" name="name" />
                    </div>
                    <div class="col-md-4">
                      <span class="span-placeholder">Modelo vehículo 2</span>
                      <input class="vs-input" label="Modelo vehículo 2" v-model="adicionales.vehiculos.modelo2" name="name" />
                    </div>
                  </div>
                  <div class="row w-full">
                    <div class="col-md-4">
                      <span class="span-placeholder" for="grados">Año vehículo 2</span>
                      <select name="cars" id="grados" v-model="adicionales.vehiculos.anio2" class="vs-input">
                        <option :value="''">seleccione...</option>
                        <option :value="tipo.Id" v-for="tipo in años" :key="">{{ tipo.Nombre }}</option>
                      </select>
                    </div>
                    <div class="col-md-8 text-center">
                      <span class="span-placeholder" for="pago2">Pago vehículo 2</span>
                      <div class="row">
                        <div class="col-md-6 content-radio">
                          <vs-radio v-model="adicionales.vehiculos.pago2" vs-name="pago2" name="pago2" vs-value="Completo"></vs-radio>
                          <span class="span-placeholder" for="Completo">Completo</span>
                        </div>
                        <div class="col-md-6 content-radio">
                          <vs-radio v-model="adicionales.vehiculos.pago2" vs-name="pago2" name="pago2" vs-value="Parcial"></vs-radio>
                          <span class="span-placeholder" for="Parcial">Parcial</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    -->
    <!-- -->


    <div class="accordion-item">
			<h2 class="accordion-header" id="headingTwo-dat">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo-dat" aria-expanded="false" aria-controls="collapseTwo-dat">Seguros</button>
			</h2>
			<div id="collapseTwo-dat" class="accordion-collapse collapse" aria-labelledby="headingTwo-dat" data-bs-parent="#accordionExample4">


				<div class="accordion-body accordion-scrolling mh-4">

         <div class="w-full">
									<div class="row">
										<div class="col-md-4 alinear">
                    <div class="span-placeholder" style="padding: 0.7em !important; margin-top:10px;">Altura</div>
                    	<vs-input class="small" v-model="estatura" name="name"  style="padding: 0.4em !important;"    v-on:keyup="validaIngersoMensual()"  v-on:keypress="isNumber($event)"/>
                    <div class="span-placeholder" style="padding: 0.7em !important; margin-top:10px;">m</div>
										
										</div>
                    
										<div class="col-md-4 alinear">
                    <div class="span-placeholder" style="padding: 0.7em; margin-top:10px;">Peso</div>
										  <vs-input class="small"  v-model="peso" name="name"  maxlength="6" v-on:keyup="validaIngersoMensual()"  v-on:keypress="isNumber($event)"  />
                    <div class="span-placeholder" style="padding: 0.7em; margin-top:10px;">Kg</div>
										</div>
                    <div class="col-md-4"></div>
									</div>
                  <br>
                  <br>
                  <hr>
						</div>

					<div class="row w-full">
						<div class="col-md-10">
							<h6>Afecciones médicas</h6>
						</div>
						<div class="col-md-2">
							<vs-switch v-model="switchAfecMedicas" @click="cambia(1)" :label="`Afecciones medicas: ${switchAfecMedicas.toString()}`" />
						</div>
						<hr />
						<div class="col-md-12" style="display: none" id="Afecciones">
							<div class="row">
								<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesAfecciones" :key="index">
									<vs-checkbox v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
									<br />
								</div>
							</div>
						</div>
					</div>

					<div class="row w-full">
						<div class="col-md-10">
							<h6>Procedimientos médicos</h6>
						</div>
						<div class="col-md-2">
							<vs-switch v-model="switchProMedicos" @click="cambia(2)" />
						</div>
						<br />
						<hr />
						<div class="col-md-12" style="display: none" id="Procedimientos">
							<br />
							<div class="row">
								<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesProcedimientos" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
									<vs-checkbox v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
									<br />
								</div>
							</div>
						</div>
					</div>

					<div class="row w-full">
						<div class="col-md-10">
							<h6>Historial médico</h6>
						</div>
						<div class="col-md-2">
							<vs-switch v-model="switchHistorial" @click="cambia(3)" />
						</div>
						<br />
						<hr />
						<div class="col-md-12" style="display: none" id="Historial">
							<br />
							<div class="row">
								<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesHistorial" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
									<vs-checkbox v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
									<br />
								</div>
							</div>
						</div>
					</div>

					<div class="row w-full">
						<div class="col-md-10">
							<h6>Hábitos</h6>
						</div>
						<div class="col-md-2">
							<vs-switch v-model="switchHabitos" @click="cambia(4)" />
						</div>
						<br />
						<hr />
						<div class="col-md-12" style="display: none" id="Habitos">
							<br />
							<div class="row">
								<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesHabitos" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
									<vs-checkbox v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
									<br />
								</div>
							</div>
						</div>
					</div>

					<div class="row w-full">
						<div class="col-md-10">
							<h6>Actividad laboral</h6>
						</div>
						<div class="col-md-2">
							<vs-switch v-model="switchAcLaboral" @click="cambia(5)" />
						</div>
						<br />
						<hr />
						<div class="col-md-12" style="display: none" id="AcLaboral">
							<br />
							<div class="row">
								<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesAcLaboral" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
									<vs-checkbox v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
									<br />
								</div>
							</div>
						</div>
					</div>

					<div class="row w-full">
						<div class="col-md-10">
							<h6>Otros</h6>
						</div>
						<div class="col-md-2">
							<vs-switch v-model="switchOtro" @click="cambia(6)" />
						</div>
						<br />
						<hr />
						<div class="col-md-12" style="display: none" id="Otro">
							<br />
							<div class="row">
								<div class="col-md-6 fuente_check" v-for="(padecimiento, index) in padecimientosOpcionesOtro" :key="index" vs-type="flex" vs-justify="left" vs-align="left" vs-lg="4" vs-sm="12" vs-xs="12">
									<vs-checkbox v-model="padecimientos" :vs-value="padecimiento.Id">{{ padecimiento.Nombre }}</vs-checkbox>
									<br />
								</div>
							</div>
						<!--	<div class="size-example">
								<div class="row">
									<div class="col-md-6">
										<vs-input size="small" label-placeholder="PESO EN KILOGRAMOS" v-model="peso" name="name" type="number" />
									</div>
									<div class="col-md-6">
										<vs-input size="small" label-placeholder="ESTATURA EN CENTIMETROS" v-model="estatura" name="name" type="number" />
									</div>
								</div>
							</div>-->
						</div>
					</div>

           <div class="row w-full">
							<div class="col-md-10">
								<h6>CITIBANAMEX</h6>
							</div>
							<div class="col-md-2">
								<vs-switch v-model="SolswitchCiti" @click="cambia(7)" />
							</div>
							<br /><br />
							<hr />
							<div class="col-md-12" style="display: none" id="Citi">
								<br />

                <div class="row w-full">
                <div class="col-md-5">Seguro de vida</div>
                <div class="col-md-2"></div>
                <div class="col-md-5">Seguro de daños</div>
                
                </div>
                <div class="row w-full">
                    <div class="col-md-5"><vs-checkbox v-model="SeguroInsolutoCiti">Saldo insoluto del crédito</vs-checkbox></div>
                    <div class="col-md-2"></div>
                    <div class="col-md-5"><vs-checkbox v-model="SeguroDanosCiti">Con la aseguradora proporcionada por citibanamex y que se reflejará en la carátula</vs-checkbox></div>
                    
                </div>

                <div class="row w-full">
                    <div class="col-md-5"><vs-checkbox v-model="SeguroMontoCiti">Monto original del crédito</vs-checkbox></div>
                                        
                </div>


							
							</div>
						</div>
				</div>
			</div>
		</div>
		<div class="row w-full">
			<div class="col-md-4">
				<button class="btn btn-second" @click="registraAseguradora()">Guardar adicionales</button>
			</div>
		</div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import { paisesNacimiento } from '@/assets/jsonFiles/paisesNacimiento.js'
export default {
  components: {
    vSelect,
  },
  data(){
		return{
      años:[],
      paisesNac: [],
      switchAfecMedicas: false,
      switchOtro: false,
      SolswitchCiti:false,
      switchAcLaboral: false,
      switchHabitos: false,
      switchHistorial: false,
      switchProMedicos: false,
      padecimientosBase: [{ Id: '', Nombre: '' }],
      padecimientos:[],
      padecimientosOpcionesAfecciones: [], 
      padecimientosOpcionesProcedimientos: [], 
      padecimientosOpcionesHistorial: [], 
      padecimientosOpcionesHabitos: [],
      padecimientosOpcionesAcLaboral:[],
      padecimientosOpcionesOtro:[],
      companiasCelular: [
        {name: 'Telcel'},
        {name: 'AT&T'},
        {name: 'Movistar'},
        {name: 'Unefon'},
        {name: 'Virgin Mobile'},
        {name: 'Freedompop'},
        {name: 'Flash Mobile'},
        {name: 'Weex'},
        {name: 'Cierto'},
        {name: 'Maz Tiempo'},
        {name: 'Oui móvil'},
        {name: 'Oxxo'},
        {name: 'Izzi móvil'},
        {name: 'Pillofon'},
        {name: 'BlueTelecomm'},
        {name: 'YO Mobile'},
        {name: 'Bait'},
        {name: 'Sky Celular'}
      ],
      Afecciones:[1,2,3,4,5,6,8,9,11,12,13,14,16,20,38,41,54,60,61,62,63,67,68,69],
      Procedimientos:[10,17,35,36],
      Historial:[7,15,22,37,39,44,45,46,47,48,49,64,70], 
      Habitos:[21,23,26,31,59],
      AcLaboral:[18,19,25,30,42,43,53,58,65,66], 
      Otro:[24,33,34,40,50,51,52,55,57],         
      adicionales:{
        autosPropios:false,
        tiempoOcupandoDomicilioActual:'0',
        tiempoOcupandoDomicilioActualMeses:'0',
        antiguedadDomicilioAnterior:'',
        companiaCelular:'',
        gradoEstudios:'',
        otroNombre:'',
        estadoNacimiento:'',
        municipioNacimiento:'',
        paisNacimiento:'',
        PaisRecidencia:'',
        telefonoFijo:'',
        telefonoOficina:'',
        identificacionPresenta:'',
        numeroIfe:'',
        nss:'',
        vigencia:null,
        fiel:'',
        participaCreditoInfonavit:false,
        calleExtranjero:'',
        numeroExteriorExtranjero:'',
        numeroInteriorExtranjero:'',
        coloniaExtranjero:'',
        estadoExtranjero:'',
        paisExtranjero:'',
        codigoPostalExtranjero:'',
        vehiculos:{
          numero:'',
          valor:'',
          marca:'',
          modelo:'',
          anio:'',
          pago: '',
          valor2:'',
          marca2:'',
          modelo2:'',
          anio2:'',
          pago2:''
        },
      },
      peso: '',
      estatura: '',
      gradosEstudio:[],
      estados:[],
      identificaciones:[],
      domicilioOcupaciones:[],
      id:'',
      email:'',
      solicitante:{},
      SeguroInsolutoCiti:'',
      SeguroDanosCiti:'',
      SeguroMontoCiti:'',
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getPaisesNacimiento()
    this.getApikey()
    this.getGradosEstudio()
    this.getEstados()
    this.getIdentificaciones()
    this.getListaDomicilioOcupaciones()
    this.getAños()
    this.listaEnfermedades()
    this.dameCaso()
    this.dameCasoLis()
  },
  watch: {
    vehiculosValor:function(newValue){
      if (this.adicionales.vehiculos.valor != null ) {
        this.adicionales.vehiculos.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    vehiculosValor2:function(newValue){
      if (this.adicionales.vehiculos.valor2 != null ) {
        this.adicionales.vehiculos.valor2= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    }
  },
  computed:{
    vehiculosValor(){
      return this.adicionales.vehiculos.valor
    },
    vehiculosValor2(){
      return this.adicionales.vehiculos.valor2
    },
  },
  methods:{
    getPaisesNacimiento() {
			this.paisesNac = paisesNacimiento;
		},
    registraAdicionales(){
      var auto1Valor = '';
      var auto2Valor = '';
      
      if (!this.adicionales.autosPropios) {
          this.adicionales.autosPropios = null;
          this.adicionales.vehiculos.numero = null;
          this.adicionales.vehiculos.valor = '';
          this.adicionales.vehiculos.marca = null;
          this.adicionales.vehiculos.modelo = null;
          this.adicionales.vehiculos.anio = null;

          this.adicionales.vehiculos.pago = null;
          this.adicionales.vehiculos.valor2 = '';
          this.adicionales.vehiculos.marca2 = null;
          this.adicionales.vehiculos.modelo2 = null;
          this.adicionales.vehiculos.anio2 = null;
          this.adicionales.vehiculos.pago2 = null;
      }
      else{
        auto1Valor = parseFloat(this.adicionales.vehiculos.valor.replace(/,/g,""));
        auto2Valor = parseFloat(this.adicionales.vehiculos.valor2.replace(/,/g,""));
      }

      let self=this
      var objRequestAdicionales = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'RegistraCoacreditadoAdicionales',
        objSolicitante: {
        EMail:this.email,
        Personales:{
          CompaniaCelular:this.adicionales.companiaCelular,
          IdentificacionPresenta:this.adicionales.identificacionPresenta,
          NumeroIdentificacion:this.adicionales.numeroIfe,
          Nss:this.adicionales.nss,
          Fiel:this.adicionales.fiel,
          TelefonoFijo:this.adicionales.telefonoFijo,
          TelefonoOficina:this.adicionales.telefonoOficina,
          VigenciaIdentificacion:this.adicionales.vigencia,
          TitularidadDomicilio:this.adicionales.titularidadDomicilio,
          ParticipaCredito:this.adicionales.participaCreditoInfonavit,
          CalleExtranjero:this.adicionales.calleExtranjero,
          NumeroExteriorExtranjero:this.adicionales.numeroExteriorExtranjero,
          NumeroInteriorExtranjero:this.adicionales.numeroInteriorExtranjero,
          ColoniaExtranjero:this.adicionales.coloniaExtranjero,
          EstadoExtranjero:this.adicionales.estadoExtranjero,
          // PaisExtranjero:this.adicionales.paisExtranjero,
          PaisExtranjero: {
            name: this.adicionales.paisExtranjero,
            noSanitiza: true,
          },
          CodigoPostalExtranjero:this.adicionales.codigoPostalExtranjero,
          PaisRecidencia:this.adicionales.PaisRecidencia
        },
        Estudios:{
          GradoEstudios:this.adicionales.gradoEstudios,
        },
        Nacionalidad:{
          OtroNombre:this.adicionales.otroNombre,
          EstadoNacimiento:this.adicionales.estadoNacimiento,
          MunicipioNacimiento:this.adicionales.municipioNacimiento,
          // PaisNacimiento:this.adicionales.paisNacimiento,
          PaisNacimiento: {
            name: this.adicionales.paisNacimiento,
            noSanitiza: true,
          },
        },
        DomicilioActual:{
          TiempoOcupandoDomicilioActual:this.adicionales.tiempoOcupandoDomicilioActual,
          AntiguedadDomicilioAnterior:this.adicionales.antiguedadDomicilioAnterior,

          TiempoViviendoCiudadMeses: this.adicionales.tiempoOcupandoDomicilioActualMeses,
        },
        Propiedades:{
          VehiculosPropios:this.adicionales.autosPropios,
          NumeroVehiculos:this.adicionales.vehiculos.numero,
          Auto1Valor:auto1Valor,
          Auto1Marca:this.adicionales.vehiculos.marca,
          Auto1Modelo:this.adicionales.vehiculos.modelo,
          Auto1Anio:this.adicionales.vehiculos.anio,

          Auto1Pago:this.adicionales.vehiculos.pago,
          Auto2Valor:auto2Valor,
          Auto2Marca:this.adicionales.vehiculos.marca2,
          Auto2Modelo:this.adicionales.vehiculos.modelo2,
          Auto2Anio:this.adicionales.vehiculos.anio2,
          Auto2Pago:this.adicionales.vehiculos.pago2,
        },
      }
    }
      this.$axios.post('/',objRequestAdicionales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Guardado exitosamente`,
              position:'top-right'
            })
            
            this.registraAseguradora()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAdicionalesSolicitud',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
      validaIngersoMensual() {
      this.estatura = this.estatura.replace(/,/g, '')
			const regex = /^(\d{1})(\.\d{0,2})?$/
			const matches = regex.test(this.estatura)
			if (matches === true) {
				this.estatura = this.estatura
			} else {
				this.estatura = this.estatura.replace(/.$/, '')
			}
    },
     validaIngersoMensualPeso() {
      this.peso = this.peso.replace(/,/g, '')
			const regex = /^(\d{1,3})?$/
			const matches = regex.test(this.peso)
			if (matches === true) {
				this.peso = this.peso
			} else {
				this.peso = this.peso.replace(/.$/, '')
			}
    },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
          Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (response.data.objContenido.Coacreditado[0]) {
              this.email= response.data.objContenido.Coacreditado[0].EMail
            }

             this.SeguroMontoCiti= response.data.objContenido.SeguroMontoCiti
            this.SeguroInsolutoCiti= response.data.objContenido.SeguroInsolutoCiti
            this.SeguroDanosCiti= response.data.objContenido.SeguroDanosCiti

            this.dameSolicitante()
            if (response.data.objContenido.EnfermedadActivos != null) {
              this.validaswitch(response.data.objContenido.EnfermedadActivos) 
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self=this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
          EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.solicitante= response.data.objContenido
            this.adicionales.companiaCelular=this.solicitante.CompaniaCelular
            this.adicionales.nss= this.solicitante.Nss
            this.adicionales.fiel= this.solicitante.Fiel
            this.adicionales.numeroIfe=this.solicitante.NumeroIdentificacion
            this.adicionales.municipioNacimiento=this.solicitante.MunicipioNacimiento
            this.adicionales.paisNacimiento=this.solicitante.PaisNacimiento
            this.adicionales.telefonoOficina=this.solicitante.TelefonoOficina
            this.adicionales.telefonoFijo=this.solicitante.TelefonoCasa
            this.adicionales.otroNombre=this.solicitante.OtroNombreAnterior
            
            this.adicionales.PaisRecidencia=this.solicitante.PaisRecidencia

            if (this.solicitante.Peso != null) {
              this.peso=this.solicitante.Peso  
            }
            if (this.solicitante.Estatura != null) {
              this.estatura=this.solicitante.Estatura
            }

            if(this.solicitante.EstadoNacimiento==null)
              this.adicionales.estadoNacimiento= ''
            else
              this.adicionales.estadoNacimiento=this.solicitante.EstadoNacimiento

            if(this.solicitante.Escolaridad==null)
              this.adicionales.gradoEstudios= ''
            else 
              this.adicionales.gradoEstudios=this.solicitante.Escolaridad

            if(this.solicitante.TipoOcupacion==null)
              this.adicionales.titularidadDomicilio= ''
            else
              this.adicionales.titularidadDomicilio= this.solicitante.TipoOcupacion

            if(this.solicitante.Identificacion==null)
              this.adicionales.identificacionPresenta= ''
            else  
              this.adicionales.identificacionPresenta= this.solicitante.Identificacion

            this.adicionales.vigencia= this.solicitante.VigenciaIdentificacion
            this.adicionales.participaCreditoInfonavit= this.solicitante.ParticipaInfovIssste
            this.adicionales.tiempoOcupandoDomicilioActual= this.solicitante.TiempoViviendoCiudad

            this.adicionales.tiempoOcupandoDomicilioActualMeses=this.solicitante.TiempoViviendoCiudadMeses ? this.solicitante.TiempoViviendoCiudadMeses : 0;

            this.adicionales.antiguedadDomicilioAnterior=this.solicitante.AntiguedadDomicilioAnterior
            //Autos
            this.adicionales.autosPropios=this.solicitante.AutosPropios
            this.adicionales.vehiculos.numero=this.solicitante.AutosPropiosNumero
            this.adicionales.vehiculos.marca=this.solicitante.AutosPropiosMarca
            this.adicionales.vehiculos.modelo=this.solicitante.AutosPropiosModelo
            if(this.solicitante.AutosPropiosAno==null)
              this.adicionales.vehiculos.anio= ''
            else
              this.adicionales.vehiculos.anio=this.solicitante.AutosPropiosAno

            this.adicionales.vehiculos.pago=this.solicitante.AutosPropiosPago

            this.adicionales.vehiculos.marca2=this.solicitante.AutosPropios2Marca
            this.adicionales.vehiculos.modelo2=this.solicitante.AutosPropios2Modelo
            if(this.solicitante.AutosPropios2Ano==null)
              this.adicionales.vehiculos.anio2= ''
            else
            this.adicionales.vehiculos.anio2=this.solicitante.AutosPropios2Ano

            this.adicionales.vehiculos.pago2=this.solicitante.AutosPropios2Pago

            if (this.solicitante.AutosPropiosValor) {
              this.adicionales.vehiculos.valor=(this.solicitante.AutosPropiosValor).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if (this.solicitante.AutosPropios2Valor) {
              this.adicionales.vehiculos.valor2=(this.solicitante.AutosPropios2Valor).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.adicionales.calleExtranjero=this.solicitante.CalleExtranjero
            this.adicionales.numeroExteriorExtranjero= this.solicitante.NumeroExteriorExtranjero
            this.adicionales.numeroInteriorExtranjero= this.solicitante.NumeroInteriorExtranjero
            this.adicionales.coloniaExtranjero= this.solicitante.ColoniaExtranjero
            this.adicionales.estadoExtranjero=this.solicitante.EstadoExtranjero
            this.adicionales.paisExtranjero=this.solicitante.PaisExtranjero
            this.adicionales.codigoPostalExtranjero=this.solicitante.CodigoPostalExtranjero
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema3',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getGradosEstudio(){
      let self=this
      var objRequestListaEscolaridades = {
        strApiKey: this.key,
        strMetodo: 'ListaEscolaridades',
      }
      this.$axios.post('/',objRequestListaEscolaridades,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.gradosEstudio=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEscolaridades',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self=this
      var objRequestListaEstados = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaEstados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getIdentificaciones(){
      let self=this
      var objRequestListaTipoIdentificaciones = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoIdentificaciones',
      }
      this.$axios.post('/',objRequestListaTipoIdentificaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let identificacionList = response.data.objContenido;
            identificacionList = identificacionList.filter(identi => identi.Id != 7);
            this.identificaciones = identificacionList;

            // 2023-05-04 Mejora en identificacion
            // this.identificaciones=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoIdentificaciones',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaDomicilioOcupaciones(){
      let self=this
      var objRequestListaDomicilioOcupaciones = {
        strApiKey: this.key,
        strMetodo: 'ListaDomicilioOcupaciones',
      }
      this.$axios.post('/',objRequestListaDomicilioOcupaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.domicilioOcupaciones=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaDomicilioOcupaciones',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    getAños(){
      let current=new Date().getFullYear()
      for (var i = 0; i < 31; i++) {
        this.años.push({ Nombre: current-i,  Id: current-i })
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    cambia(val){
      
      if(val==1){
        if(!this.switchAfecMedicas){
          $('#Afecciones').show()
        }else{
          $('#Afecciones').hide()
        }
      }
      if(val==2){
        if(!this.switchProMedicos){
          $('#Procedimientos').show()
        }else{
          $('#Procedimientos').hide()
        }
      }
      if(val==3){
        if(!this.switchHistorial){
          $('#Historial').show()
        }else{
          $('#Historial').hide()
        }
      }
      if(val==4){
        if(!this.switchHabitos){
          $('#Habitos').show()
        }else{
          $('#Habitos').hide()
        }
      }
      if(val==5){
        if(!this.switchAcLaboral){
          $('#AcLaboral').show()
        }else{
          $('#AcLaboral').hide()
        }
      }    
      if(val==6){
        if(!this.switchOtro){
          $('#Otro').show()
        }else{
          $('#Otro').hide()
        }
      } 
       if(val==7){
        if(!this.SolswitchCiti){
          $('#Citi').show()
        }else{
          $('#Citi').hide()
        }
      } 
      
    },
    listaEnfermedades(){
      let self= this
      var objRequestListaEnfermedades = {
        strApiKey: this.key,
        strMetodo: 'listaConceptosAseguradora',
      }
      this.$axios.post('/',objRequestListaEnfermedades,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

            this.padecimientosBase = response.data.objContenido
            var cuenta = this.padecimientosBase.length
            var a = 0
            var b = 0
            var c = 0
            var d = 0
            var f = 0
            var g = 0

            this.padecimientosBase.sort(function(a, b) {
              return a.Id - b.Id;
            })

            for (var i = 0; i < cuenta; i++) {
              if(this.padecimientosBase[i].Id==this.Afecciones[a]){ 
                a++
                this.padecimientosOpcionesAfecciones.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
              if(this.padecimientosBase[i].Id==this.Procedimientos[b]){ 
                b++
                this.padecimientosOpcionesProcedimientos.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
              if(this.padecimientosBase[i].Id==this.Historial[c]){ 
                c++
                this.padecimientosOpcionesHistorial.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
              if(this.padecimientosBase[i].Id==this.Habitos[d]){ 
                d++
                this.padecimientosOpcionesHabitos.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
              if(this.padecimientosBase[i].Id==this.AcLaboral[f]){ 
                f++
                this.padecimientosOpcionesAcLaboral.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
              if(this.padecimientosBase[i].Id==this.Otro[g]){ 
                g++
                this.padecimientosOpcionesOtro.push({ Id: this.padecimientosBase[i].Id, Nombre: this.padecimientosBase[i].Nombre })
              }
            }

            this.padecimientosOpcionesAfecciones.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })
            this.padecimientosOpcionesProcedimientos.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })
            this.padecimientosOpcionesHistorial.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })
            this.padecimientosOpcionesHabitos.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })
            this.padecimientosOpcionesAcLaboral.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })
            this.padecimientosOpcionesOtro.sort(function(a, b) {
              if (a.Nombre.toLowerCase() < b.Nombre.toLowerCase()) {
                return -1;
              }
            })

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en listaConceptosAseguradora',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraAseguradora(){
      let self= this
      let enfermAct=[]
      let enfermedades=[]
      
      for (var i = 0; i < this.padecimientos.length; i++) {
        enfermedades.push({"Concepto":this.padecimientos[i]})
      }
      
      if(this.switchAfecMedicas)
        enfermAct.push(1)
        
      if(this.switchProMedicos)
        enfermAct.push(2)

      if(this.switchHistorial)
        enfermAct.push(3)

      if(this.switchHabitos)
        enfermAct.push(4)

      if(this.switchAcLaboral)
        enfermAct.push(5)

      if(this.switchOtro)
        enfermAct.push(6)

      if(this.SolswitchCiti)
        enfermAct.push(7)
        
      
      var enActivos = enfermAct.toString()
      var objRequestRegistraCuentasBancarias = {
        strFolioCaso: this.id,
        strApiKey:this.key,
        strMetodo:'RegistraAseguradora',
        objSolicitante:{
          EMail:this.email,
          Peso:this.peso,
          Estatura:this.estatura,
          IdCaso:this.id,
          Activos:enActivos,
          AsignaAct:2,
          DatosAseguradora:enfermedades,

          SeguroMontoCiti: this.SeguroMontoCiti,
          SeguroInsolutoCiti: this.SeguroInsolutoCiti,
          SeguroDanosCiti: this.SeguroDanosCiti,
        }
      }
      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAseguradora',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCasoLis(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (response.data.objContenido.Coacreditado[0]) {
              this.email= response.data.objContenido.Coacreditado[0].EMail
            }
            this.dameSolicitanteLis()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitanteLis(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.peso=response.data.objContenido.Peso
            this.estatura=response.data.objContenido.Estatura
            if(this.email!=''){
              for (var i = 0; i < response.data.objContenido.DatosAseguradora.length; i++) {
                this.padecimientos.push(response.data.objContenido.DatosAseguradora[i].Id)
              }
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    validaswitch(act){

      const words = act.split(',');

      for (var i = 0; i < words.length; i++) {
            
        if(words[i]==1){
          this.switchAfecMedicas = true
          $('#Afecciones').show()
        }
        if(words[i]==2){
          this.switchProMedicos = true
          $('#Procedimientos').show()
        }
        if(words[i]==3){
          this.switchHistorial = true
          $('#Historial').show()
        }
        if(words[i]==4){
          this.switchHabitos = true
          $('#Habitos').show()
        }
        if(words[i]==5){
          this.switchAcLaboral = true
          $('#AcLaboral').show()
        }
        if(words[i]==6){
          this.switchOtro = true
          $('#Otro').show()
        }
         if(words[i]==7){
          this.SolswitchCiti = true
          $('#Citi').show()
        }
        
      }
    }
  }
}
</script>

<style lang="css" scoped>

.input-antiguedad-am {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-antiguedad-fecha {
  margin-top: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: lightgrey;
}
.input-antiguedad-fecha span {
  color: inherit;
  font-size: medium;
  margin: 0;
  text-align: center;
  padding-top: 6px;
  display: block;
}

.fuente_check{
  
  font-size: 10px;

}

.content-radio {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
